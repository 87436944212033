import { typedEntries } from "../../utils/objects";
import {
  EntitlementValueObject as EntitlementValueObjectDto,
  InvoiceLineItemView as InvoiceLineItemViewDto,
  InvoiceView as InvoiceViewDto,
  ReclaimEdition as ReclaimEditionDto,
  SubscriptionChange as SubscriptionChangeDto,
  SubscriptionFrequency as SubscriptionFrequencyDto,
  SubscriptionOption as SubscriptionOptionDto,
  SubscriptionOptions as SubscriptionOptionsDto,
  SubscriptionPrice as SubscriptionPriceDto,
  TeamMembershipSummary as TeamMembershipSummaryDto,
  TeamPricingSummary as TeamPricingSummaryDto,
} from "./client";
import {
  EntitlementIntegrations,
  EntitlementSupport,
  EntitlementTable,
  EntitlementTableRow,
  EntitlementValueObject,
  InvoiceLineItemView,
  InvoiceView,
  ReclaimEditionStr,
  ReclaimEditionV2,
  SubscriptionChange,
  SubscriptionFrequencyStr,
  SubscriptionOption,
  SubscriptionOptions,
  SubscriptionPrice,
  TeamMembershipSummary,
  TeamPricingSummary,
} from "./Team.types";

export const dtoToTeamMembershipSummary = (dto: TeamMembershipSummaryDto): TeamMembershipSummary => ({
  ...dto,
});

export const dtoToSubscriptionFrequencyStr = (dto: SubscriptionFrequencyDto): SubscriptionFrequencyStr =>
  dto as SubscriptionFrequencyStr;
export const dtoToReclaimEditionV2 = (dto: ReclaimEditionDto): ReclaimEditionV2 => {
  switch (dto) {
    case ReclaimEditionDto.LITE:
    case ReclaimEditionDto.STARTER:
    case ReclaimEditionDto.BUSINESS:
    case ReclaimEditionDto.ENTERPRISE:
      return dto as ReclaimEditionV2;
    default:
      throw new Error(`Received non-V2 edition: ${dto}`);
  }
};

export const dtoToReclaimEditionStr = (dto: ReclaimEditionDto): ReclaimEditionStr => dto as ReclaimEditionStr;

export const subscriptionFrequencyStrToDto = (subFrequency: SubscriptionFrequencyStr): SubscriptionFrequencyDto =>
  subFrequency as SubscriptionFrequencyDto;
export const reclaimEditionToDto = (edition: ReclaimEditionStr): ReclaimEditionDto => edition as ReclaimEditionDto;

export const dtoToEntitlementValueObject = <T, IS_TOP extends boolean = boolean>(
  dto: EntitlementValueObjectDto
): EntitlementValueObject<T, IS_TOP> =>
  ({
    value: dto.value as unknown as T,
    ...(((!dto.nextEdition || dto.nextValue === undefined) as IS_TOP)
      ? {
          isTop: true,
          nextEdition: void 0,
          nextValue: void 0,
        }
      : {
          isTop: false,
          nextEdition: dtoToReclaimEditionStr(dto.nextEdition as ReclaimEditionDto),
          nextValue: dto.nextValue as unknown as T,
        }),
  } as unknown as EntitlementValueObject<T, IS_TOP>);

export const dtoToNumberEntitlementValue = (dto: unknown): number => {
  const num = Number(dto);
  return num >= 10000 ? Infinity : num;
};

/**
 * Back-end passes `Infinity` as `"Infinity"`.
 * This function behaves exactly like
 * `dtoToEntitlementValueObject` only it can
 * also parse the string infinity values
 * @param dto The data transfer object
 * @returns a `EntitlementValueObject<number>` object
 */
export const dtoToNumberEntitlementValueObject = <IS_TOP extends boolean = boolean>(
  dto: EntitlementValueObjectDto
): EntitlementValueObject<number, IS_TOP> =>
  ({
    value: dtoToNumberEntitlementValue(dto.value),
    ...(!!dto.nextEdition && dto.nextValue !== undefined
      ? {
          isTop: false,
          nextEdition: dtoToReclaimEditionStr(dto.nextEdition),
          nextValue: dtoToNumberEntitlementValue(dto.nextValue),
        }
      : {
          isTop: true,
        }),
  } as unknown as EntitlementValueObject<number, IS_TOP>);

export const dtoToEntitlementTableRow = (dto: Record<string, EntitlementValueObjectDto>): EntitlementTableRow => ({
  MAX_TEAM_SIZE: dtoToNumberEntitlementValueObject(dto.MAX_TEAM_SIZE),
  SCHEDULER_WEEKS: dtoToNumberEntitlementValueObject(dto.SCHEDULER_WEEKS),
  MAX_TASKS: dtoToNumberEntitlementValueObject(dto.MAX_TASKS),
  MAX_CALENDARS: dtoToNumberEntitlementValueObject(dto.MAX_CALENDARS),
  MAX_SYNCS: dtoToNumberEntitlementValueObject(dto.MAX_SYNCS),
  MAX_HABITS: dtoToNumberEntitlementValueObject(dto.MAX_HABITS),
  CUSTOM_BLOCKING: dtoToEntitlementValueObject<boolean>(dto.CUSTOM_BLOCKING),
  MAX_SCHEDULING_LINKS: dtoToNumberEntitlementValueObject(dto.MAX_SCHEDULING_LINKS),
  MAX_1_ON_1_ORGANIZE: dtoToNumberEntitlementValueObject(dto.MAX_1_ON_1_ORGANIZE),
  MAX_1_ON_1_ATTEND: dtoToNumberEntitlementValueObject(dto.MAX_1_ON_1_ATTEND),
  INTEGRATIONS: dtoToEntitlementValueObject<EntitlementIntegrations>(dto.INTEGRATIONS),
  SUPPORT: dtoToEntitlementValueObject<EntitlementSupport>(dto.SUPPORT),
  SSO: dtoToEntitlementValueObject<boolean>(dto.SSO),
});

export const dtoToEntitlementTable = (
  dto: Record<string, Record<string, EntitlementValueObjectDto>>
): EntitlementTable => {
  const v2Table = (
    Object.entries(dto) as [ReclaimEditionV2, Record<ReclaimEditionV2, EntitlementValueObjectDto>][]
  ).reduce((table, [edition, dtoRow]) => {
    table[edition] = dtoToEntitlementTableRow(dtoRow);
    return table;
  }, {} as Record<ReclaimEditionV2, EntitlementTableRow>);

  return {
    // TODO: these all SHOULD be in the response, but often
    // seem to not be so I'm dropping in fallbacks to prevent
    // errors. -SG
    ASSISTANT: v2Table.LITE,
    LEGACY_PRO_TRIAL: v2Table.STARTER,
    LEGACY_TEAM_TRIAL: v2Table.BUSINESS,
    NONE: v2Table.LITE,
    PRO: v2Table.STARTER,
    REWARD: v2Table.BUSINESS,
    TEAM: v2Table.BUSINESS,
    TRIAL: v2Table.BUSINESS,
    TRIAL_BUSINESS: v2Table.BUSINESS,

    ...v2Table,
  };
};

export const dtoToSubscriptionPrice = (dto: SubscriptionPriceDto): SubscriptionPrice => ({
  ...dto,
});

export const dtoToSubscriptionOption = (dto: SubscriptionOptionDto): SubscriptionOption => ({
  ...dto,
  // Backend isn't sending back Infinity for whatever reason
  maxSeats: dto.maxSeats > 1000 ? Infinity : dto.maxSeats,
  prices:
    dto.prices &&
    typedEntries(dto.prices).reduce((prices, [freq, price]) => {
      prices[freq] = dtoToSubscriptionPrice(price);
      return prices;
    }, {} as SubscriptionOption["prices"]),
});

export const dtoToSubscriptionOptions = (dto: SubscriptionOptionsDto): SubscriptionOptions => ({
  ...dto,
  options: typedEntries(dto.options).reduce((options, [edition, option]) => {
    options[edition] = dtoToSubscriptionOption(option);
    return options;
  }, {} as SubscriptionOptions["options"]),
});

export const dtoToInvoiceLineItemView = (dto: InvoiceLineItemViewDto): InvoiceLineItemView => ({
  ...dto,
  periodStart: dto.periodStart || undefined,
  periodEnd: dto.periodEnd || undefined,
});

export const dtoToInvoiceView = (dto: InvoiceViewDto): InvoiceView => ({
  ...dto,
  dueDate: dto.dueDate || undefined,
  lines: dto.lines.map(dtoToInvoiceLineItemView),
});

export const subscriptionChangeToDto = (data: SubscriptionChange): SubscriptionChangeDto => ({
  ...data,
  frequency: subscriptionFrequencyStrToDto(data.frequency),
  edition: reclaimEditionToDto(data.edition),
});

export const dtoToTeamPricingSummary = <HOMOGENEOUS extends boolean>(
  dto: TeamPricingSummaryDto
): TeamPricingSummary<HOMOGENEOUS> =>
  ({
    ...dto,
    trialEnd: dto.trialEnd || undefined,
    daysLeftInTrial: dto.daysLeftInTrial || undefined,
    subscriptionFrequency: dto.subscriptionFrequency || undefined,
    trial: dto.trial || undefined,
    edition: dto.edition ? dtoToReclaimEditionStr(dto.edition) : undefined,
    entitlements: (dto.entitlements && dtoToEntitlementTableRow(dto.entitlements)) as EntitlementTableRow,
  } as unknown as TeamPricingSummary<HOMOGENEOUS>);
